@use '../utilities/colors';
@use '../utilities/variables';

table {
	border-spacing: 0;
	margin: 16px 0;
	width: 100%;
}

td {
	border-color: colors.$off-black;
	border-style: solid;
	border-width: 1px 0 0;
	max-width: 1px;
	padding: 16px;
}

th {
	text-align: left;

	button {
		color: colors.$heading-color;
		padding-right: variables.$sort-icon-size;
		position: relative;
		text-transform: uppercase;
		width: 100%;

		&:hover,
		&:active,
		&:focus {
			color: colors.$white;
		}
	}
}

.heading--name {
	width: 50%;
}

.heading--artist,
.heading--album {
	width: 20%;
}

.heading--date {
	width: 10%;
}
