.list--inline {
	display: inline;
	margin: 0;
	padding: 0;

	li {
		display: inline;
	}

	li + li {
		&::before {
			content: ', ';
		}
	}
}
