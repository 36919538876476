@use '../utilities/colors';
@use '../utilities/variables';

h1 {
	color: colors.$white;
	font-size: 36px;
	font-weight: bold;
	margin: 0;
}

h2,
th {
	color: colors.$heading-color;
	font-size: variables.$body-font-size;
	letter-spacing: 2px;
	margin: 0;
	text-transform: uppercase;
}

h2 {
	font-weight: normal;
	padding: 16px 16px 0;
}
