#container {
	display: flex;
	flex-direction: column;
	overflow: hidden;
}

#main {
	display: flex;
	flex: 1 1 auto;
	height: 100vh;
}

#sidebar {
	overflow: auto;
	width: 20%;
}

#content {
	overflow: auto;
	padding: 32px;
	position: relative;
	width: 80%;
}
