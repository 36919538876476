@use 'sass:color';
@use '../utilities/colors';
@use '../utilities/variables';

#sidebar {
	background-color: colors.$sidebar-background-color;

	.search {
		padding: 0 16px 16px;
	}

	.no-results {
		line-height: 1;
		padding: 16px;
	}
}

#title {
	background-image: url('../../svg/logo.svg');
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	font-size: 0;
	height: 72px;
	margin: 16px auto 0;
	width: 234px;
}

#nav {
	align-items: center;
	display: flex;
	justify-content: center;
	list-style: none;
	margin: 0;
	padding: 0;

	li + li {
		margin-left: 16px;
	}
}

#playlists {
	list-style: none;
	margin: 0;
	padding: 0;
}

.playlist {
	border-color: transparent;
	border-style: solid;
	border-width: 0 0 0 variables.$button-border-width;
	color: colors.$body-color;
	display: block;
	line-height: 1;
	padding: 16px;

	&:hover,
	&:active,
	&:focus {
		background-color: colors.$button-background-color--hover;
		color: colors.$button-color--hover;
		text-decoration: none;
	}
}

.playlist--active {
	background-color: color.scale(colors.$sidebar-background-color, $lightness: 5%);
	border-color: colors.$primary;
	color: colors.$white;
}
