@use '../utilities/colors';
@use '../utilities/variables';

.prefix {
	border-radius: variables.$button-border-radius 0 0 variables.$button-border-radius;

	&:hover,
	&:active {
		& + .postfix {
			background-color: colors.$input-background-color--hover;

			&::before {
				background-color: colors.$input-background-color--hover;
			}
		}
	}

	&:focus {
		& + .postfix {
			box-shadow: 0 0 0 variables.$focus-border-width colors.$secondary;
		}
	}
}
