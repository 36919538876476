@use '../utilities/colors';
@use '../utilities/variables';

.postfix {
	background-color: colors.$input-background-color;
	border-radius: 0 variables.$button-border-radius variables.$button-border-radius 0;
	position: relative;

	&::before {
		background-color: colors.$input-background-color;
		bottom: 0;
		content: '';
		display: block;
		left: calc(variables.$focus-border-width * -1);
		position: absolute;
		top: 0;
		width: variables.$focus-border-width;
	}

	&:hover,
	&:active,
	&:focus {
		background-color: colors.$input-background-color--hover;
	}
}
