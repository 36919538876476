$body-font-family: raleway, 'Helvetica Neue', sans-serif;
$body-font-size: 18px;

$button-border-radius: 8px;
$button-border-width: 4px;
$button-padding: 12px;

$focus-border-width: 4px;

$sort-icon-size: 40px;

$spinner-size: 80px;
