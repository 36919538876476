@use '../utilities/colors';
@use '../utilities/variables';

input {
	background-color: colors.$input-background-color;
	border-radius: variables.$button-border-radius;
	border-width: 0;
	color: colors.$input-color;
	line-height: 1;
	padding: variables.$button-padding;
	width: 100%;

	&:hover,
	&:active,
	&:focus {
		background-color: colors.$input-background-color--hover;
		color: colors.$input-color--hover;
	}
}

::placeholder {
	color: colors.$gray;
}

.search {
	display: flex;
}
