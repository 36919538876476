@font-face {
	font-display: swap;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	src: url('../../fonts/raleway-regular-webfont.woff2') format('woff2'), url('../../fonts/raleway-regular-webfont.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: Raleway;
	font-style: normal;
	font-weight: 500;
	src: url('../../fonts/raleway-semibold-webfont.woff2') format('woff2'), url('../../fonts/raleway-semibold-webfont.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: Raleway;
	font-style: normal;
	font-weight: bold;
	src: url('../../fonts/raleway-bold-webfont.woff2') format('woff2'), url('../../fonts/raleway-bold-webfont.woff') format('woff');
}
