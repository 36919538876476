@use '../utilities/colors';
@use '../utilities/variables';

* {
	box-sizing: border-box;
}

a {
	color: colors.$primary;
	text-decoration: none;

	&:hover,
	&:active,
	&:focus {
		color: colors.$primary-light;
		text-decoration: underline;
	}
}

body {
	background-color: colors.$body-background-color;
	font-family: variables.$body-font-family;
}

button,
input,
h1,
h2,
p,
td,
th,
ul {
	color: colors.$body-color;
	font-size: variables.$body-font-size;
	line-height: 1.4;
}

p,
ul {
	margin: 16px 0 0;
}

button,
input {
	&:focus {
		box-shadow: 0 0 0 variables.$focus-border-width colors.$secondary;
		outline: none !important; /* stylelint-disable-line declaration-no-important */
	}
}
