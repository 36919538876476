@use '../utilities/colors';
@use '../utilities/variables';

button {
	background-color: colors.$button-background-color;
	border-color: transparent;
	border-style: solid;
	border-width: 0;
	color: colors.$button-color;
	cursor: pointer;
	line-height: 1;
	padding: variables.$button-padding;

	&:hover,
	&:active,
	&:focus {
		background-color: colors.$button-background-color--hover;
		border-color: colors.$button-border-color--hover;
		color: colors.$button-color--hover;
	}
}

.button--primary {
	background-color: colors.$primary;
	border-color: colors.$primary-dark;
	border-radius: variables.$button-border-radius;
	border-width: 0 variables.$button-border-width variables.$button-border-width 0;
	color: colors.$black;
	font-weight: 500;

	&:hover,
	&:active,
	&:focus {
		background-color: colors.$primary-light;
		border-color: colors.$primary-dark;
		color: colors.$black;
	}
}

.button--icon {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 16px;
	font-size: 0;
	width: 48px;
}

.button--clear {
	&:focus {
		box-shadow: none;

		path {
			fill: colors.$secondary;
		}
	}
}

.button--search {
	pointer-events: none;

	&:focus {
		box-shadow: none;

		path {
			fill: colors.$secondary;
		}
	}
}

.button--sort {
	color: colors.$white;

	&::after {
		background-image: url('../../svg/arrow.svg');
		background-position: center;
		background-repeat: no-repeat;
		background-size: 16px;
		bottom: 0;
		content: '';
		display: block;
		height: variables.$sort-icon-size;
		position: absolute;
		right: 0;
		top: 0;
		width: variables.$sort-icon-size;

		.table--sort-desc & {
			transform: rotate(180deg);
		}
	}
}
