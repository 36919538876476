@use '../utilities/variables';

#spinner {
	align-items: center;
	display: flex;
	font-size: 0;
	inset: 0;
	justify-content: center;
	position: absolute;

	&::after {
		animation-duration: 4s;
		animation-iteration-count: infinite;
		animation-name: spin;
		animation-timing-function: linear;
		background-image: url('../../svg/spinner.svg');
		background-repeat: no-repeat;
		background-size: contain;
		content: '';
		display: block;
		height: variables.$spinner-size;
		width: variables.$spinner-size;
	}
}
